import { render, staticRenderFns } from "./SearchTextInput.vue?vue&type=template&id=be358c6c&scoped=true"
import script from "./SearchTextInput.vue?vue&type=script&lang=js"
export * from "./SearchTextInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be358c6c",
  null
  
)

export default component.exports