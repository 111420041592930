import { admin2 } from './instance'

// Target Template
export const GetNotifyTargetTemplateAppointmentConfigOptions = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyTargetTemplate/appointmentConfigOptions`,
  })
}
export const GetProgressNotificationTargetTemplate = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyTargetTemplate`,
    params: {
      name,
      start,
      limit,
    },
  })
}

export const GetProgressNotificationTargetTemplateCount = async ({ shopId, name }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyTargetTemplate/count`,
    params: { name },
  })
}

export const FindProgressNotificationTargetTemplate = async ({ shopId, id }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyTargetTemplate/${id}`,
  })
}

export const CreateProgressNotificationTargetTemplate = async ({
  shopId,
  name,
  type,
  appointmentConfig,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/notifyTargetTemplate`,
    data: {
      name,
      type,
      appointmentConfig,
    },
  })
}

export const UpdateProgressNotificationTargetTemplate = async ({
  shopId,
  id,
  name,
  appointmentConfig,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/notifyTargetTemplate/${id}`,
    data: {
      name,
      appointmentConfig,
    },
  })
}

export const DeleteProgressNotificationTargetTemplate = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/notifyTargetTemplate/${id}`,
  })
}

// Content Template
export const GetProgressNotificationContentTemplate = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyContentTemplate`,
    params: {
      name,
      start,
      limit,
    },
  })
}

export const GetProgressNotificationContentTemplateCount = async ({ shopId, name }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyContentTemplate/count`,
    params: { name },
  })
}

export const FindProgressNotificationContentTemplate = async ({ shopId, id }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyContentTemplate/${id}`,
  })
}

export const CreateProgressNotificationContentTemplate = async ({
  shopId,
  name,
  type,
  appointmentInfoConfig,
  shopInfoConfig,
  imageTextConfig,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/notifyContentTemplate`,
    data: {
      name,
      type,
      appointmentInfoConfig,
      shopInfoConfig,
      imageTextConfig,
    },
  })
}

export const UpdateProgressNotificationContentTemplate = async ({
  shopId,
  id,
  name,
  appointmentInfoConfig,
  shopInfoConfig,
  imageTextConfig,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/notifyContentTemplate/${id}`,
    data: {
      name,
      appointmentInfoConfig,
      shopInfoConfig,
      imageTextConfig,
    },
  })
}

export const DeleteProgressNotificationContentTemplate = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/notifyContentTemplate/${id}`,
  })
}

// Notify Settings
export const GetProgressNotificationSettings = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifySettingTarget`,
    params: {
      name,
      start,
      limit,
    },
  })
}

export const GetProgressNotificationSettingsCount = async ({ shopId, name }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifySettingTarget/count`,
    params: { name },
  })
}

export const FindProgressNotificationSettings = async ({ shopId, id }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifySettingTarget/${id}`,
  })
}

export const CreateProgressNotificationSettings = async ({
  shopId,
  type,
  NotifyTargetTemplateId,
  NotifySettings,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/notifySettingTarget`,
    data: {
      type,
      NotifyTargetTemplateId,
      NotifySettings,
    },
  })
}

export const UpdateProgressNotificationSettings = async ({
  shopId,
  id,
  NotifyTargetTemplateId,
  NotifySettings,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/notifySettingTarget/${id}`,
    data: {
      NotifyTargetTemplateId,
      NotifySettings,
    },
  })
}

export const DeleteProgressNotificationSettings = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/notifySettingTarget/${id}`,
  })
}

export const ResetProgressNotificationSettings = async ({
  shopId,
  type,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/notifySettingTarget/resetToDefault`,
    data: { type },
  })
}

export const PreviewProgressNotificationSettings = async ({
  shopId,
  contents,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/notifySettingTarget/notifySettingContentPreview`,
    data: { contents },
  })
}

// 新增通知內容範本
export const CreateNotifyContentTemplate = async ({
  shopId,
  name,
  type,
  appointmentInfoConfig,
  shopInfoConfig,
  imageTextConfig,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/notifyContentTemplate`,
    data: {
      name,
      type,
      appointmentInfoConfig,
      shopInfoConfig,
      imageTextConfig,
    },
  })
}

// 更新通知內容範本
export const UpdateNotifyContentTemplate = async ({
  shopId,
  name,
  id,
  appointmentInfoConfig,
  shopInfoConfig,
  imageTextConfig,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/notifyContentTemplate/${id}`,
    data: {
      name,
      appointmentInfoConfig,
      shopInfoConfig,
      imageTextConfig,
    },
  })
}

// 取得通知內容範本
export const FindNotifyContentTemplate = async ({ shopId, id }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyContentTemplate/${id}`,
  })
}

// 取得預設通知內容範本
export const FindDefaultNotifyContentTemplate = async ({ shopId, defaultKey }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyContentTemplate/default`,
    params: {
      defaultKey,
    },
  })
}

// 取得通知內容範本列表
export const GetNotifyContentTemplate = async ({
  shopId,
  name,
  start,
  limit,
  isDefault,
  types,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyContentTemplate`,
    params: {
      name,
      start,
      limit,
      isDefault,
      types,
    },
  })
}

// 取得通知內容範本列表數量
export const GetNotifyContentTemplateCount = async ({ shopId, name }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/notifyContentTemplate/count`,
    params: { name },
  })
}
